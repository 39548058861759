import RedRightArrow from "../../assets/images/red-right-arrow.png";
import BlueLeftArrow from "../../assets/images/blue-left-arrow.png";
import WhereSectionBg from "../../assets/images/who-can-join-bg.png";

const Where = () => {
  return (
    <section className="w-full flex justify-center py-10 md:py-20 relative">
      <div className="w-4/5 max-w-screen-xl flex flex-col space-y-5 md:space-y-6 lg:space-y-14">
        <div className="flex flex-row justify-center items-center space-x-5">
          <img
            data-aos="fade-right"
            className="w-[60px] sm:w-[100px] md:w-[120px] lg:w-[150px] xl:w-[202px]"
            src={RedRightArrow}
            alt="Right arrow"
          />
          <h4
            data-aos="fade-up"
            className="text-center text-[#EFC166] text-2xl sm:text-[35px] md:text-[40px] lg:text-[53px] font-black font-['Axiforma'] uppercase leading-tight"
          >
            Nerede ?
          </h4>
          <img
            data-aos="fade-left"
            className="w-[60px] sm:w-[100px] md:w-[120px] lg:w-[150px] xl:w-[202px]"
            src={BlueLeftArrow}
            alt="Left arrow"
          />
        </div>
        <p
          data-aos="fade-up"
          className="text-sm md:text-[23px] text-white font-medium font-['Axiforma'] text-center leading-normal"
        >
          Genç Enerji Programı, İstanbul ve Diyarbakır ofislerimizde fiziksel
          olarak gerçekleşecek.
        </p>
      </div>
      <img
        className="w-3/5 absolute -right-1/4 -top-1/3 z-[-1]"
        src={WhereSectionBg}
        alt="where section bg "
      />
    </section>
  );
};

export default Where;
