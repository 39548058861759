import Header from "./components/Header";
import LogoSlider from "./components/LogoSlider";
import WhoCanJoin from "./components/WhoCanJoin";
import Where from "./components/Where";
import Footer from "./components/Footer";
import VideoSection from "./components/VideoSection";
import Process from "./components/Process";

import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  AOS.init({
    once: false,
    delay: 150,
  });
  return (
    <div className="App overflow-hidden">
      <Header />
      <LogoSlider />
      <VideoSection />
      <WhoCanJoin />
      <Where />
      {/*<HowSection />*/}
      <Process />
      <Footer />
      <a href="#" className="nesil-open-modal">
        Çerez Tercihleri
      </a>
    </div>
  );
}

export default App;
