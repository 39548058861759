import { useState } from "react";
// import Swiper core and required modules
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const LogoSlider = () => {
  const [logos, setLogos] = useState([
    "logo 150x150-01.png",
    "logo 150x150-02.png",
    "logo 150x150-03.png",
    "logo 150x150-04.png",
    "logo 150x150-05.png",
    "logo 150x150-10.png",
    "logo 150x150-01.png",
    "logo 150x150-02.png",
    "logo 150x150-03.png",
    "logo 150x150-04.png",
    "logo 150x150-05.png",
    "logo 150x150-10.png",
    "logo 150x150-01.png",
    "logo 150x150-02.png",
    "logo 150x150-03.png",
    "logo 150x150-04.png",
    "logo 150x150-05.png",
    "logo 150x150-10.png",
    "logo 150x150-11.png",
  ]);

  const getItemOfScreenSize = (width) => {
    if (width < 600) {
      return 2;
    } else if (width < 800) {
      return 3;
    } else if (width < 1100) {
      return 4;
    } else {
      return 6;
    }
  };

  return (
    <section className="w-full flex justify-center bg-white px-10">
      <Swiper
        // install Swiper modules
        modules={[Autoplay]}
        spaceBetween={1}
        slidesPerView={getItemOfScreenSize(window.innerWidth)}
        data-swiper-autoplay="2000"
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop={true}
        className="mySwiper"
      >
        {logos.map((logo, index) => {
          return (
            <SwiperSlide
              key={index}
              className="justify-center p-4"
              style={{ display: "flex" }}
            >
              <img
                className="h-[130px]"
                src={require("../../assets/images/Logos/" + logo)}
                alt=""
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};
export default LogoSlider;
