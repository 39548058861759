import HeaderImage from "../../assets/images/header-image1.png";
import HeaderIconGroup from "../../assets/images/header-icon-group.png";
import HeaderImage2 from "../../assets/images/header-image2.png";
import HeaderImage3 from "../../assets/images/header-image3.png";
import HeaderImage4 from "../../assets/images/header-image4.png";
import ArrowRight from "../../assets/images/arrow-right.png";
import EksimText from "../../assets/images/eksim-text.png";
import Logo from "../../assets/images/logo.png";

const Header = () => {
  return (
    <section className="w-full flex justify-center bg-[url('../../assets/images/header-bg.png')] bg-cover bg-left lg:bg-right-top bg-no-repeat relative">
      <img
        className="absolute w-[140px] lg:w-[500px] left-12 lg:left-20 top-14"
        src={HeaderIconGroup}
        alt="header_image"
      />
      <div className="w-4/5 lg:w-11/12 max-w-screen-xl flex flex-col md:flex-row relative pt-24 pb-16 lg:py-32">
        <img
          className="w-[80px] lg:w-[170px] absolute top-14 right-5 lg:right-24 select-none z-[20]"
          src={Logo}
          alt="Logo"
        />
        <div className="w-full md:w-1/2 space-y-7 lg:space-y-20 " lang="tr">
          <div className="flex flex-col space-y-5">
            <div className="flex items-center justify-center w-[80px] h-[80px] lg:w-[128px] lg:h-[128px] bg-[url('../../assets/images/header-image3.png')] bg-contain bg-center bg-no-repeat">
              <p className="text-[11px] lg:text-[17px] text-white font-black rotate-[346deg] font-['Axiforma']">
                4.DÖNEM
              </p>
            </div>
            <h4
              data-aos="fade-right"
              className="text-xl lg:text-[37px] xl:text-[52px] text-[#00E2D7] font-[350] font-['Axiforma'] leading-tight tracking-tighter"
            >
              Sürdürülebilir bir kariyer için bitmeyen enerjinin adresi:{" "}
            </h4>
            <h2
              data-aos="fade-right"
              className="text-[#E5AE41] text-3xl lg:text-[50px] xl:text-[65px] font-[800] font-['Axiforma'] leading-tight tracking-tight"
            >
              EKSİM GENÇ ENERJİ
              <br />
              <span className="text-white">PROGRAMI!</span>
            </h2>
          </div>
          <div className="flex flex-col space-y-3">
            <h6
              data-aos="fade-up"
              className="text-[#E5AE41] text-xs lg:text-[23px] font-[800] font-['Axiforma']"
            >
              Son Başvuru Tarihi:{" "}
              <span className="text-[#00E2D7] text-lg lg:text-[35px] font-extrabold font-['Axiforma']">
                31 MART 2024
              </span>
            </h6>
            <div className="flex flex-row space-x-3 md:space-x-5 items-center">
              <img
                data-aos="fade-right"
                className="w-[80px] md:w-[100px] lg:w-[231px]"
                src={ArrowRight}
                alt="Email"
              />
              <p
                data-aos="fade-left"
                className="text-base lg:text-[23px] text-[white] font-['Axiforma'] font-bold mb-1"
              >
                eksimgencenerji.com
              </p>
            </div>
          </div>
        </div>
        <div className="w-full md:w-1/2 flex items-center justify-center">
          <img
            className="w-[350px] lg:w-[674px] select-none -mr-[28px] -mt-[50px] lg:-mt-[100px] md:-mr-[200px] xl:-mr-[260px]"
            src={HeaderImage}
            alt="header_image"
          />
        </div>
      </div>
      <a href="https://www.eksim.com.tr/" target="blank">
        <img
          className="w-[100px] lg:w-[150px] absolute right-10 lg:right-20 bottom-10 lg:bottom-20 select-none z-[5]"
          src={EksimText}
          alt="Eksim"
        />
      </a>
      <img
        className="w-[100px] lg:w-[141px] absolute bottom-0 right-0"
        src={HeaderImage2}
        alt="header_image"
      />
    </section>
  );
};

export default Header;
