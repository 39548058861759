import { useEffect, useRef, useState } from "react";
import PlayButton from "../../assets/images/play-btn.png";
import Icon1 from "../../assets/images/VideoSectionIcon1.png";
import Icon2 from "../../assets/images/VideoSectionIcon2.png";
import Icon3 from "../../assets/images/VideoSectionIcon3.png";
import PurpleCircle from "../../assets/images/purple-circle.png";

//video linki
//import Video from "../../assets/video/trying_video.mp4";

import { HiPause } from "react-icons/hi";

const VideoSection = () => {
  const video = useRef(null);
  const playButton = useRef(null);
  const [videoState, setVideoState] = useState(true);

  const videoControl = () => {
    if (video.current.paused || video.current.ended) {
      // Play the video
      video.current.play();
      setVideoState(false);
    } else {
      // Pause the video
      video.current.pause();
      setVideoState(true);
    }
  };

  useEffect(() => {
    if (video.current.paused) {
      setVideoState(true);
    } else {
      setVideoState(false);
    }
  }, []);

  return (
    <section className="w-full flex justify-center py-16 ">
      <div className="w-4/5 max-w-screen-xl flex flex-col items-center justify-center space-y-10">
        <h4
          data-aos="fade-up"
          className="text-base md:text-[20px] lg:text-[25px] xl:text-[30px] text-[#E5AE41] font-['Axiforma'] font-black uppercase text-center leading-normal"
          lang="tr"
        >
          Eksim Holding Genç Enerji İle <br /> Yeni Çalışma Arkadaşlarını
          Arıyor!
        </h4>
        <p
          data-aos="fade-up"
          className="w-full sm:w-3/5 text-white text-center text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl font-normal font-['Axiforma']"
        >
          Enerjiden gıdaya yenilenebilir kaynaklardan sermaye yatırımına kadar farklı sektörlerde deneyim kazanma fırsatına davetlisin.
        </p>
        <p
          data-aos="fade-up"
          className="w-full sm:w-3/5 text-white text-center text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl font-normal font-['Axiforma']"
        >
          Eğitim programları ile kendini geliştirebileceğin ve kariyer
          yolculuğuna güçlü bir başlangıç yapabileceğin Eksim Genç Enerji yeni mezun işe
          alım programı burada seni bekliyor!
        </p>
        <p
          data-aos="fade-up"
          className="w-full sm:w-3/5 text-white text-center text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl font-normal font-['Axiforma']"
        >
          31 Mart tarihine kadar başvurunu gerçekleştir, enerji dolu bir kariyer için ilk adımını at.
        </p>
        <div data-aos="zoom-in" className="w-full relative">
          <div className="group video w-full h-[200px] sm:h-[350px] md:h-[400px] lg:h-[550px] xl:h-[718px] rounded-[30px] sm:rounded-[65px] md:rounded-[80px] lg:rounded-[95px] xl:rounded-[113px] bg-white border-[#00E2D7] border-solid border-[3px] flex justify-center items-center z-[100] relative overflow-hidden">
            <video ref={video} className="w-full h-full">
              <source src= "#" />
            </video>
            {videoState ? (
              <img
                onClick={() => {
                  videoControl();
                }}
                ref={playButton}
                className="w-[70px] sm:w-[100px] md:w-[140px] lg:w-[160px] xl:w-[193px] absolute bg-white rounded-full hover:cursor-pointer"
                src={PlayButton}
                alt="Play Button"
              />
            ) : (
              <div
                onClick={() => {
                  videoControl();
                }}
                className="hidden pause-button absolute hover:cursor-pointer group-hover:block"
              >
                <HiPause className="text-[200px] fill-[#272c6b] bg-white rounded-full" />
                <img className="top-0 absolute" src={PurpleCircle} alt="" />
              </div>
            )}
          </div>
          <img
            className="absolute -right-4 top-0 w-[40px] sm:w-[55px] md:w-[70px] lg:w-[100px] xl:w-[128px] -z-[1]"
            src={Icon1}
            alt=""
          />
          <img
            className="absolute left-0 -bottom-1 sm:-bottom-1 md:-bottom-2 lg:-bottom-3 xl:-bottom-4 w-[40px] sm:w-[55px] md:w-[70px] lg:w-[100px] xl:w-[126px] -z-[1]"
            src={Icon2}
            alt=""
          />
          <img
            className="absolute bottom-3 -right-[30px] sm:-right-10 md:-right-12 lg:-right-20 xl:-right-24 sm:bottom-5 w-[55px] sm:w-[75px] md:w-[90px] lg:w-[120px] xl:w-[153px] -z-[1]"
            src={Icon3}
            alt=""
          />
        </div>
      </div>
    </section>
  );
};
export default VideoSection;
