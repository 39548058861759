import LeftIcon from "../../assets/images/apply-left-icon.png";
import RightIcon from "../../assets/images/apply-right-icon.png";
import SquareIcon1 from "../../assets/images/how-square-icon.png";
import SquareIcon2 from "../../assets/images/how-square-icon.png";
import SquareIcon3 from "../../assets/images/how-square-icon.png";
import SquareIcon4 from "../../assets/images/how-square-icon.png";
import SquareIcon5 from "../../assets/images/how-square-icon.png";
import Logo from "../../assets/images/logo.png";
import ProcessStep1 from "../../assets/images/process/1.svg";
import ProcessStep2 from "../../assets/images/process/2.svg";
import ProcessStep3 from "../../assets/images/process/3.svg";
import ProcessStep4 from "../../assets/images/process/4.svg";
import ProcessStep5 from "../../assets/images/process/5.svg";
import ProcessStep6 from "../../assets/images/process/6.svg";
import ProcessSvg from "./ProcessSvg";

const Process = () => {
  const processSteps = [
    {
      icon: ProcessStep1,
      date: "19 ŞUBAT",
      title: "Başvurular Başlıyor!",
      description:
        "Kariyerinde yepyeni bir döneme ne dersin? Programa başvur, ilk adımı at!",
      color: "#F1AC19",
    },
    {
      icon: ProcessStep2,
      date: "1 NİSAN",
      title: "Genel Yetenek ve İngilizce Testi",
      description: "Değerlendirme sürecinde öne çık!",
      color: "#38A097",
    },
    {
      icon: ProcessStep3,
      date: "15 NİSAN",
      title: "Video Mülakat ve Kişilik Envanteri",
      description: "Bize kendinden bahset!",
      color: "#DA0270",
    },
    {
      icon: ProcessStep4,
      date: "29 NİSAN",
      title: "İnsan Kaynakları ve İş Birimi Mülakatı",
      description: "Biraz da iş konuşalım!",
      color: "#117CCF",
    },
    {
      icon: ProcessStep5,
      date: "3 HAZİRAN",
      title: "Teklif Zamanı",
      description: "Dönüşün için sabırsızlanıyoruz!",
      color: "#38A097",
    },
    {
      icon: ProcessStep6,
      date: "1 TEMMUZ",
      title: "İşe Başlangıç",
      description: "Aramıza hoş geldin!",
      color: "#752CB2",
    },
  ];

  return (
    <section className="w-full flex flex-col items-center py-10 md:py-20 relative">
      <div className="w-4/5 max-w-screen-xl space-y-20 z-10">
        <div className="w-full flex justify-center items-center">
          <img
            className="w-[80px] sm:w-[100px] md:w-[140px] lg:w-[160px] xl:w-[227px]"
            src={Logo}
            alt="logo"
          />
        </div>
        <h4
          data-aos="fade-up"
          className="text-center text-[#EFC166] text-2xl sm:text-[35px] md:text-[40px] lg:text-[53px] font-black font-['Axiforma'] uppercase leading-tight"
          lang="tr"
        >
          Süreç Haritamız
        </h4>
        <div className="w-full flex flex-col space-y-5 md:hidden">
          {processSteps.map((step, index) => (
            <div
              className="flex flex-row items-center space-x-3"
              key={`processStep-${index}`}
            >
              <div
                data-aos="zoom-in"
                className="w-full max-w-[120px] sm:max-w-[150px] transition-all duration-200"
              >
                <img className="w-full" src={step.icon} alt={step.title} />
              </div>
              <div>
                <h4
                  data-aos="fade-left"
                  className="font-black text-base sm:text-lg transition-all duration-200"
                  style={{ color: step.color }}
                >
                  {step.date}
                </h4>
                <p
                  data-aos="fade-left"
                  className="font-bold text-sm sm:text-base transition-all duration-200"
                  style={{ color: step.color }}
                >
                  {step.title}
                </p>
                <p
                  data-aos="fade-left"
                  className="text-white font-light text-xs sm:text-sm transition-all duration-200"
                >
                  {step.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="w-full md:block hidden">
          <ProcessSvg />
        </div>
      </div>
      <div className="w-4/5 max-w-screen-xl space-y-8 md:space-y-16 mt-[120px] sm:mt-[120px] md:mt-[167px] lg:mt-[200px] xl:mt-[220px] z-10">
        <div className="w-full flex justify-center">
          <div
            data-aos="zoom-in"
            className="apply-now-btn flex flex-row items-center space-x-4"
          >
            <img className="w-6 h-11" src={LeftIcon} alt="Left icon" />
            <a
              className="apply-btn text-[#65B3B2] text-base sm:text-[20px] md:text-[26px] lg:text-[36px] xl:text-[39px] font-['Axiforma'] font-medium text-center whitespace-nowrap hover:text-white animate-[flash_1s_ease-in-out_infinite]"
              href="https://eksim.hrpeak.com/jsq6lzyubms4wzlbukm86jmzhw.job"
              target="blank"
            >
              HEMEN BAŞVUR
            </a>
            <img className="w-6 h-11" src={RightIcon} alt="Right icon" />
          </div>
        </div>
      </div>
      <img
        className="w-[25px] lg:w-[35px] xl:w-[43px] top-10 left-[100px] md:left-[280px] lg:left-[370px] xl:left-[570px] absolute -z-[1] "
        src={SquareIcon1}
        alt="SquareIcon1"
      />
      <img
        className="w-[55px] lg:w-[70px] xl:w-[82px] top-10 right-[90px] md:right-[270px] lg:right-[350px] xl:right-[500px] absolute -z-[1]"
        src={SquareIcon2}
        alt="SquareIcon2"
      />
      <img
        className="w-[40px] md:w-[60px] lg:w-[80px] xl:w-[94px] sm:top-80 top-40 left-[80px] md:left-[200px] lg:left-[300px] absolute -z-[1]"
        src={SquareIcon3}
        alt="SquareIcon3"
      />
      <img
        className="w-[45px] sm:w-[65px] lg:w-[90px] xl:w-[116px] left-[80px] sm:left-[200px] lg:left-[270px] bottom-80 sm:bottom-96 absolute"
        src={SquareIcon4}
        alt="SquareIcon4"
      />
      <img
        className="w-[47px] sm:w-[60px] lg:w-[78px] xl:w-[85px] right-[80px] sm:right-[270px] lg:right-[400px] bottom-80 absolute -z-[1]"
        src={SquareIcon5}
        alt="SquareIcon5"
      />
    </section>
  );
};

export default Process;
