import Logo from "../../assets/images/logo.png";
import YellowUpArrow from "../../assets/images/yellow-up-arrow.png";
import IconsGroup from "../../assets/images/icons-group.png";
import CircleDots from "../../assets/images/circle-dots.png";

const Footer = () => {
  return (
    <section className="w-full flex justify-center">
      <div className="w-11/12 max-w-screen-xl flex flex-row justify-between items-center">
        <img
          className="w-[80px] sm:w-[100px] md:w-[140px] lg:w-[160px] xl:w-[227px]"
          src={Logo}
          alt="logo"
        />

        <div className="flex w-2/3 justify-center items-center relative">
          <img
            className="w-[694px] absolute -mt-40"
            src={CircleDots}
            alt="Circle Dots"
          />
          <img
            data-aos="fade-up"
            className="w-[30px] sm:w-[50px] md:w-[70px] lg:w-[80px] xl:w-[94px] z-[4]"
            src={YellowUpArrow}
            alt="YellowUpArrow"
          />
        </div>

        <img
          className="w-[80px] sm:w-[100px] md:w-[140px] lg:w-[160px] xl:w-[227px]"
          src={IconsGroup}
          alt="Icons Group"
        />
      </div>
    </section>
  );
};

export default Footer;
